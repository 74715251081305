<template>
  <b-row>
    <b-col sm="12">
      <my-card title="Daftar Kategori Item">
        <template slot="button">
          <button-add v-if="cP(58)" :to="{name: 'itemcategories-add'}" variant="primary"></button-add>
        </template>
        <template slot="body">
          <data-tables ref="datatables" endpoint="v1/item_categories" :fields="fields">
            <template v-slot:0="{item}">
              <span :class="{'font-weight-bolder': !item[4]}">{{item[0]}}</span>
            </template>
            <template v-slot:1="{item}">
              <span :class="{'font-weight-bolder': !item[4], 'ml-1':item[4]}">{{item[4] ? '-' : ''}} {{item[1]}}</span>
            </template>
            <template v-slot:3="data">
              <div class="float-none">
                <button-link v-if="cP(59)" size="sm" :to="{name:'itemcategories-edit',params:{id: data.item[3]}}" feather-icon="EditIcon"></button-link>
                <button-delete v-if="cP(60)" @ondelete="deleteRow" :id="data.item[3]" :name="`${data.item[0]} (${data.item[1]})`"></button-delete>
              </div>
            </template>
          </data-tables>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import ButtonAdd from '@/my-components/ButtonAdd.vue'
import DataTables from '@/my-components/DataTables.vue'
import ButtonDelete from '@/my-components/ButtonDelete.vue'
import ButtonLink from '@/my-components/ButtonLink.vue'

export default {
  components:{
    ButtonAdd,
    DataTables,
    ButtonDelete,
    ButtonLink,
  },
  data() {
    return {
      fields: {
        0:{sortable: true, searchable: true, label:'Kode'},
        1:{sortable: true, searchable: true, label:'Nama'},
        2:{sortable: true, searchable: true, label:'Keterangan'},
        3:{sortable: false, searchable: false, label:''},
      }
    }
  },
  methods:{
    async deleteRow(payload){
      try {
        await this.$store.dispatch('itemcategories/destroy',payload)
        this.$refs.datatables.refresh()
        this.toastSuccess("OK","Data Berhasil Dihapus!")
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style>

</style>